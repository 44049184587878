.cases {

    &__list {
        width: 100%;
        

        @include for-size (tablet-landscape-up) {
           padding-left: 30%; 
        }
        
    }

    &__item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;





        &:before {
            content: " ";
            position: relative;
            top: 0px;
            left: 0;
            display: block;
            width: 150px;
            height: 150px;
            background-image: url(../images/fonarik.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            z-index: 5;

            @include for-size (tablet-landscape-up) {
                position: absolute;
                top: -45px;
                left: -32%;
                width: 30%;
                height: 100%;
            }
        }

        &:first-child:before {
            background-image: url(../images/kosmetika.png);
        }

        &:last-child:before {
            background-image: url(../images/sredstvo.png);
        }
        
        &:not(:last-child) {
            margin-bottom: rem(120);
        }

        &-content {
            width: 100%;
            margin-bottom: rem(30);

            @include for-size (tablet-portrait-up) {
              width:calc(50% - 20px);  
            }
           
            li {
                line-height: rem(30);

                &:first-child {
                    font-weight: 800;
                    font-size: rem(24);
                    color: $dark-gray;
                } 
            }
           
        }
    }

    &__title {
        width: 100%;
        position: relative;
        font-size: rem(24);
        margin-bottom: rem(50);

        &:before {
            width: 175px;
            background-color: $dark-gray;
            left: -80px;
        }
    }

    &__results {
        width: 100%;
        font-weight: 800;
        line-height: 30px;

        &:not(:last-child) {
            font-size: rem(24);
            color: $dark-gray;
        }

        &_big {
            font-size: rem(24);
        }
    }
}