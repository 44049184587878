html, body, div, span, h1, h2, h3, h4, h5, h6, p, em, img, strong, sub, sup, b,
u, i, dl, dt, dd, ol, ul, li, fieldset, form, label, table, tbody, tfoot,
thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer,
header, hgroup, menu, nav, section, summary, time, mark, audio, video{margin:0;padding:0;border:0;outline:0;vertical-align:baseline;background:transparent;font-size:100%;list-style-type:none;box-sizing:border-box;}
a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;text-decoration:none;color:inherit;outline:none;}
button:active, button:focus{outline:none;}
a:active, a:focus{outline:none;}
input:active, input:focus{outline:none;}
table{border-collapse:collapse;border-spacing:0;}
td, td img{vertical-align:top;}
input, select, button, textarea{margin:0;font-size:100%;background-color:transparent;}
input[type="checkbox"]{vertical-align:middle;}
input[type="radio"]{vertical-align:text-bottom;}
sub{vertical-align:sub;font-size:smaller;}
sup{vertical-align:super;font-size:smaller;}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section{display:block;}
html{overflow-y:scroll;}
body{line-height:1;background:#fff;color:#000;text-align:left;}
input, select, button, textarea{border:none;}
label, input[type="button"], input[type="submit"], button{cursor:pointer;}
select:focus,
select:visited,
option:focus,
option:visited {
    outline: none;
}
button {
	padding: 0;
}

div{box-sizing: border-box!important;}