.benefits {
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        @include for-size (desktop-up) {
            justify-content: space-between;
        }
        
    }

    &__item {
        width: 100%;
        max-width: 260px;
        line-height: rem(24);
        position: relative;
        margin-bottom: rem(40);

        @include for-size (mobile) {
          text-align: center;
        }

        @include for-size (tablet-portrait-up) {
            width: 45%;
          }
        
          @include for-size (tablet-landscape-up) {
            width: 40%;
          }
          @include for-size (desktop-min) {
            width: 30%;
          }
          @include for-size (desktop-up) {
            width: 21%;
          }
          @include for-size (big-desktop-up) {
            width: 23%;
          }
    }


    &__text {

        &:before {
            content: '';
            display: block;
            width: 50px;
            height: 10px;
            background-color: $dark-gray;
            margin-bottom: 10px;

            @include for-size (mobile) {
              margin: 0 auto 10px;
            }
        }
    }

    &__icon {
        width: 85px;
        height: 85px;
        margin-bottom: 10px;
        opacity: .27;
    }
}