html, body {
  box-sizing: border-box;
  font-size: 12px;

  @include for-size (tablet-portrait-up) {
    font-size: 13px
  }

  @include for-size (tablet-landscape-up) {
    font-size: 14px
  }
  @include for-size (desktop-min) {
    font-size: 15px
  }
  @include for-size (desktop-up) {
    font-size: 16px
  }
  @include for-size (big-desktop-up) {
    font-size: 18px
  }
}

body {
  font-family: $main-font, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  color: $gray;
  overflow: hidden;
}

.visibily-hidden {
  display: none;
  visibility: hidden;
  opacity: 0;
}

p {
  font-size: rem(18);
  margin-bottom: 10px;
  line-height: rem(21);
}

.container {
  width: 100%;

  margin: 0 auto;
  padding: 0 20px;
  @include for-size (tablet-portrait-up) {
    font-size: 13px;
    max-width: 748px;
  }

  @include for-size (tablet-landscape-up) {
    font-size: 14px;
    max-width: 860px;
  }
  @include for-size (desktop-min) {
    font-size: 15px;
    max-width: 984px;
  }
  @include for-size (desktop-up) {
    font-size: 16px;
    max-width: 1180px;
  }
  @include for-size (big-desktop-up) {
    font-size: 18px;
    max-width: 1720px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

.section {
  position: relative;
  padding-bottom: rem(200);

  &_orange {
    background-color: $orange;
    color: $white;

    @include for-size (mobile) {
      padding-top: 40px;
      padding-bottom: 80px;
    }
    
    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: rem(-99);
      left: 50%;
      width: 82%;
      transform: translateX(-50%);
      height: rem(100);
      background-color: $orange;
      z-index: 5;

      @include for-size (mobile) {
        display: none;
      }
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: rem(-99);
      left: 50%;
      width: 82%;
      transform: translateX(-50%);
      height: rem(100);
      background-color: $orange;
      z-index: 5;
      @include for-size (mobile) {
        display: none;
      }
    }
  }

  &_gray {
    background-color: $gray;
    color: $white;
    padding: rem(200) 0 rem(165);

    @include for-size (mobile) {
      padding: 7.5rem 0 2.312rem;
    }
  }

  &-title {
    position: relative;
    max-width: 630px;
    font-weight: 800;
    font-size: rem(48);
    line-height: rem(52);
    text-align: center;
    margin: 0 auto rem(100);

    &_orange {
      font-weight: 800;
      color: $orange;
    }

    &_left {
      text-align: left;
      margin-left: 0;
    }
  }
}

.title_decor:before {
  content: '';
  display: block;
  position: absolute;
  bottom: -30px;
  left: -50px;
  width: 100px;
  height: 10px;
  background-color: $orange;
}

.hidden-title {
  display: block;
  font-weight: 800;
  line-height: 1;
  padding-top: 15px;

  @include for-size (desktop-up) {
    display: none;
  }
}

.icon {
  width: 100%;
  height: 100%;
}


.btn {
  display: inline-block;
  width: rem(390);
  line-height: rem(73);
  font-weight: 800;
  font-size: rem(24);
  text-align: center;
  background-color: $orange;
  border-radius: 35px;
  color: $white;

  -webkit-box-shadow: 8px 17px 43px -16px rgba(255,75,43,0.62);
  -moz-box-shadow: 8px 17px 43px -16px rgba(255,75,43,0.62);
  box-shadow: 8px 17px 43px -16px rgba(255,75,43,0.62);

  @include for-size (mobile) {
    margin: 0 auto;
    display: block;
    max-width: 280px;
  }

  &_foo {
    width: 280px;
    @include for-size (tablet-landscape-up) {
      width: 33%;
    }
  }
}

.lightgray {
  background-color: $light-gray;
  padding-bottom: rem(135);
}

.icon-contract {
  width: 25px;
  height: 25px;
  margin-top: 10px;
  @include for-size (tdblet-landscape-up) {
   margin: 0;
  }
}

