.we-work {
    padding: 58px 0 130px;

    &:before {
        content: 'Just';
        position: absolute;
        bottom: -94px;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        font-size: rem(480);
        font-weight: 800;
        color: #3e3e3e;
        opacity: .1;

        @include for-size (mobile) {
            font-size: 14rem;
            bottom: -35px;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__item {
        width: 100%;
        padding-right: 10%;
        padding-bottom: 35px;

        @include for-size (tablet-portrait-up) {
            width: 50%;
        }

        &:before {
            content: '';
            display: block;
            width: 50px;
            height: 10px;
            margin-bottom: 12px;
            background-color: $orange; 
        }
    }
}