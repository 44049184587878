.footer {
    color: rgba(255,255,255,.5);
    padding-top: 3rem;
    padding-bottom: 3rem;

    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 45px;
    }

    &__item {
        width: 100%;
        margin-bottom: rem(40);
        text-align: center;

        @include for-size (tablet-portrait-up) {
            width: 30%;
            text-align: left;
        }
    }

    &_call {
        color: rgba(255,255,255,.5);

        @include for-size (mobile) {
            padding-right: 0;
            font-size: 21px;
            padding: 0;
            color: #ff4b2b;
        }

        &:before {
            @include for-size (mobile) {
                display: none;
            }
        }
        
    }

    &_logo {
        opacity: .5;
        margin: 0 auto;

        @include for-size (tablet-portrait-up) {
            margin: 0;
        }


    }

    &__desc {
        font-size: rem(16);
        line-height: rem(24);

        @include for-size (mobile) {
            font-size: 1.4rem;
        }
    }
}

.social {
    display: flex;
    justify-content: center;

    @include for-size (tablet-portrait-up) {
        justify-content: flex-end;
    }
    

    &__item {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    &__link {
        display: block;
        width: rem(40);
        height: rem(40);
        opacity: .5;

        .icon {
            fill: #fff;

            &:hover {
                fill: $orange;
            }
        }

        
    }
}