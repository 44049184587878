.page-slogan {
    width: 58.33%;
    font-size: rem(30);
    line-height: rem(36);
    color: $dark-gray;
    padding-left: rem(35);

    @include for-size (mobile) {
        width: 100%;
        padding-top: 10px;
        padding-left: 0;
    }
    
}

.contacts {

    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: rem(80);

        @include for-size (tablet-landscape-up) {
            flex-wrap: nowrap;
            align-items: flex-end;
        }
    }

    &-title_min {
        width: 70%;
        font-size: rem(98);

        @include for-size (mobile) {
            width: 100%;
            font-size: rem(60);
        }

        @include for-size (tablet-landscape-up) {
            width: 58%;
        }

        @include for-size (desktop-min) {
            width: 48%;
        }

        @include for-size (desktop-up) {
            width: 41%;
        }
    }

    &__form {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        text-align: center;
        margin: 0 auto;
        padding: rem(24) rem(35);

        @include for-size (mobile) {
            padding: rem(24) 0;  
        }

        @include for-size (tablet-landscape-up) {
            justify-content: space-between;
            background-color: #fff;
            width: calc(100% - 20px);
            flex-flow: row nowrap;
        }

        @include for-size (desktop-min) {
            width: calc(100% - 200px);
        }
    }

    &__field {
        width: 280px;
        text-align: center;
        font-style: italic;
        line-height: 3.8rem;
        background-color: $white;
        border-radius: 35px;
        margin-bottom: 20px;

        @include for-size (tablet-landscape-up) {
            margin-bottom: 0;
            width: calc(50% - 15px);
            background: transparent;
        }

        &-wrap {
            position: relative;
            display: flex;
            flex-direction: column;
            
            background-color: $light-gray;
            border-radius: 35px;

            @include for-size (tablet-landscape-up) {
                flex-direction: row;
                justify-content: space-between; 
                width: 60%;  
            }

            &:after {
                @include for-size (tablet-landscape-up) {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 6px;
                    height: 60%;
                    background-color: #cdcdcd;
                }
            }   
        }
    }
}

.thank-you {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: $light-gray;
    color: $orange;
    font-weight: 800;
    z-index: 10;
}