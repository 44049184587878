/*
 * Все паршалы импортировать в этот файл
 */

@import 'utils/reset';
@import 'utils/mixins';

@import 'base/fonts.scss';
@import 'base/variables.scss';

@import 'base.scss';

@import 'layouts/top.scss';
@import 'layouts/banner.scss';
@import 'layouts/benefits.scss';
@import 'layouts/why.scss';
@import 'layouts/we-work.scss';
@import 'layouts/services.scss';
@import 'layouts/cases.scss';
@import 'layouts/reviews.scss';
@import 'layouts/contacts.scss';
@import 'layouts/footer.scss';

