.reviews {

    &__wrap {
        // display: flex;
        // flex-flow: row wrap;
        // justify-content: space-around;
    }

    &__item {
        width: 100%;
        padding-left: rem(180);
        margin-right: auto;
        position: relative;

        @include for-size (mobile) {
            padding-left: rem(75);
        }

        @include for-size (tablet-portrait-up) {
            width: 75%;
        }

        &:nth-child(even) {
            margin-left: auto;
        }

        &:not(:last-child) {
            margin-bottom: 90px;
        }

        &:before {
            content: ',,';
            display: block;
            position: absolute;
            top: -133px;
            left: 0;
            font-weight: 800;
            font-size: rem(200);
            color: $orange;
        @include for-size (mobile) {
            font-size: rem(100);
            top: -60px;
        }

        }
    }
    &__author {
        font-weight: 800;
        font-size: rem(24);
        margin-bottom: 12px;

        &-desc {
            font-weight: 800;
            font-size: rem(24);
            color: $orange;
            margin-bottom: rem(35);
        }
    }

    p {
        line-height: rem(32);
    }
}