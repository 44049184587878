@mixin for-size($size) {
    @if $size == mobile {
        @media (max-width: 599px) {
            @content;
        }
    } @else if $size == tablet-portrait-up {
        @media (min-width: 600px) {
            @content;
        }
    } @else if $size == tablet-landscape-up {
        @media (min-width: 900px) {
            @content;
        }
    } @else if $size == desktop-min {
        @media (min-width: 1024px) {
            @content;
        }
    } @else if $size == desktop-up {
        @media (min-width: 1200px) {
            @content;
        }
    } @else if $size == big-desktop-up {
        @media (min-width: 1800px) {
            @content;
        }
    }
}

@function rem($px) {
    @return ($px / 16) + rem;
}
