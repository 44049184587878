.why {
    background-image: url(../images/logo/JustSell_only_logo.svg);
    background-size: 50%;
    background-position: center right;
    background-repeat: no-repeat;

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        counter-reset: li;
        padding-top: rem(80);
        padding-bottom: rem(50);
    }

    &__item {
        width: 100%;
        margin-bottom: rem(40);

        @include for-size (tablet-landscape-up) {
            width: 27%;
        }
        
        &:before {
            counter-increment: li;                 /* Инкрементирует счетчик*/
            content: "0" counter(li) " ";
            position: relative;
            left: -50px;
            font-weight: 800;
            font-size: rem(100);
            color: $orange;

            @include for-size (mobile) {
                left: 0;
                opacity: .6;
            }
        }

        &-title {
            font-size: rem(24);
            line-height: rem(30);
            margin-bottom: 10px;
        }

        p {
            line-height: rem(32);
        }
    }

}