.header {
    padding: 10px 0;
}
.logo {
    display: block;
    width: 170px;

    @include for-size (mobile) {
        width: 95px;
    }

    &__img {
        width: 100%;
        height: auto;
    }
}

.nav {
    display: flex;
    align-items: center;
    
    &__list {
        display: none;
        
        @include for-size (desktop-min) {
            display: flex;
        }
    }

    &__item:not(:last-child) {
        margin-right: 35px;
    }

    &__link {
        font-size: rem(18);
        color: #292929;
    }

    &__call {
        position: relative;
        font: 800 rem(24) $main-font;
        padding: 0 40px 6px 35px;
        color: #2d2d2d;
        @include for-size (mobile) {
            padding-right: 0;
            
        }


        &:before {
            @include for-size (mobile) {
                // display: none;
            }
            content: '';
            position: absolute;
            bottom: -7px;
            right: 0;
            display: block;
            width: 98px;
            height: 10px;
            background-color: $orange;
        }
    }
}