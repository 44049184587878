.services {
    background-color: $light-gray;
    padding: rem(125) 0 rem(200);

    .container:before {
        content: 'Sell';
        position: absolute;
        top: 25%;
        left: -7%;
        transform: rotate(-90deg);
        display: block;
        font-size: rem(480);
        font-weight: 800;
        color: #3e3e3e;
        opacity: .1;

        @include for-size (mobile) {
            top: 19%;
            left: -31%;
            font-size: 20rem;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-bottom: rem(73);
        position: relative;
        z-index: 5;

        @include for-size (tablet-landscape-up) {
            justify-content: space-between; 
        }
    }

    &__item {
        width: calc(31% - 16px);
        min-width: 260px;

        @include for-size (mobile) {
            width: 90%;
        }

        @include for-size (tablet-landscape-up) {
            width: calc(25% - 16px);
        }

        &:not(:first-child) {
            background-color: $white;
            font-size: rem(18);
            text-align: center;
            margin-bottom: 60px;
        }

        &:first-child { 
            display: none; 
            text-align: right;
            @include for-size (desktop-up) {
                display: block;
            }
        }
        
    }

    .btn {
        margin-left: 25%;

        @include for-size (mobile) {
            margin: 0 auto;
            display: block;
        }
    }
}

.plan {
    &__item {
        // height: rem(21);
        line-height: rem(56);
        padding: 0px 10px;

        &:first-child {
            position: relative;
            height: 165px;
            font-weight: 800;
            font-size: rem(30);
            line-height: rem(50);
            padding-top: rem(50);
        }
    }

    &__title {
        &:after {
            content: ' ';
            display: block;
            width: 80%;
            height: 10px;
            margin: 25px auto 0px;
            background: $gray;
        }

        &.start:after {
            background-color: #dadada;
        }

        &.profi:after {
            background-color: $orange;
        }   
    }

    &__icons {
        position: absolute;
        top: -21px;
        left: 50%;
        transform: translateX(-50%);
    }
}