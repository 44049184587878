.banner {
  height: 100vh;
  min-height: 850px;

  @include for-size(desktop-min) {
    background-image: url(../images/phones.png);
    background-size: 34%;
    background-repeat: no-repeat;
    background-position: 93% 40%;
  }

  &-wrap {
    background-image: url(../images/bg_sell.svg);
    background-position: center top;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__content {
    width: 588px;
    @include for-size(mobile) {
      width: 100%;
    }
  }
}

.page {
  &-title {
    font-weight: 800;
    font-size: rem(116);
    color: $gray;
    margin-bottom: 25px;
    position: relative;

    @include for-size(mobile) {
      font-size: 54px;
      line-height: 0.9;
      max-width: 280px;
    }

    &_small {
      display: block;
      font-size: rem(48);
      line-height: rem(73);
      color: $orange;

      @include for-size(mobile) {
        font-size: 24px;
        line-height: 1;
        width: 80%;
      }
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      bottom: -35px;
      left: -50px;
      width: 100px;
      height: 10px;
      background-color: $dark-gray;

      @include for-size(mobile) {
        bottom: -18px;
      }
    }
  }

  &-desc {
    font-size: rem(21);
    line-height: rem(24);
    color: $gray;

    &_bold {
      font-weight: 800;
      line-height: rem(24);
    }

    &-wrap {
      position: relative;
      text-align: left;
      padding-left: 32%;
      margin-bottom: rem(48);

      @include for-size(mobile) {
        padding-left: 0;
        padding-top: 40%;
      }

      &:before {
        content: '';
        position: absolute;
        top: -60px;
        right: 0px;
        width: 170px;
        height: 170px;
        display: block;
        background-image: url(../images/phones.png);
        background-size: contain;
        background-repeat: no-repeat;

        @include for-size(tablet-portrait-up) {
          top: auto;
          bottom: -270px;
          right: 0px;
          width: 270px;
          height: 270px;
        }

        @include for-size(tablet-landscape-up) {
          top: auto;
          bottom: -300px;
          right: -100px;
          width: 320px;
          height: 320px;
        }

        @include for-size(desktop-min) {
          display: none;
        }
      }
    }
  }
}
